import React, { Fragment } from 'react';
import {
  BreadCrumbSection,
  Button,
  Close,
  CustomerDetailsSideBar,
  DataCard,
  Loader,
  Modal,
  Table,
  Toast,
} from '../../components';
import { Link, useParams } from 'react-router-dom';
import {
  useMutateApproveOfflineRepayment,
  useMutateDeclineOfflineRepayment,
} from '../../Providers-new/OfflineRepayment';
import { insertSpaceBeforeCapitalLetter, nairaAmount } from '../../lib/Utils';
import { FileIcon } from '../../components/Icons';
import moment from 'moment';
import { useOfflineRepayment } from '../../Providers-new/LoanModification';
import { UrlFromS3Provider } from '../../Providers';

const ManualRepaymentDetails = () => {
  let { id } = useParams();

  const { offlineRepayments, loading: opLoading } = useOfflineRepayment({
    variables: {
      id: id,
    },
  });

  let currentOfflineRepayment = offlineRepayments[0];

  const {
    handleApproveRepayHideToast,
    approveOfflineRepayLoading,
    approveOfflineRepaymentData,
    handleApproveOfflineRepayment,
    handleApproveRepayModalToggle,
  } = useMutateApproveOfflineRepayment();

  const {
    handleDeclineRepayHideToast,
    declineOfflineRepayLoading,
    declineOfflineRepaymentData,
    handleDeclineOfflineRepayment,
    handleDeclineRepayModalToggle,
  } = useMutateDeclineOfflineRepayment();
  return (
    <>
      {opLoading ? (
        <Loader loadingText="loading..." />
      ) : (
        <Fragment>
          <BreadCrumbSection />

          <CustomerDetailsSideBar>
            <article className="customer-info">
              <span className="cover-image" />
              {currentOfflineRepayment ? (
                <div className="avatar">{`
                                  ${currentOfflineRepayment.portfolio.user.firstName
                                    .charAt(0)
                                    .toUpperCase()}
                                  ${currentOfflineRepayment.portfolio.user.lastName
                                    .charAt(0)
                                    .toUpperCase()}
                                `}</div>
              ) : (
                <div className="avatar">...</div>
              )}
              {currentOfflineRepayment ? (
                <h3>{`${currentOfflineRepayment.portfolio.user.firstName} ${currentOfflineRepayment.portfolio.user.lastName}`}</h3>
              ) : (
                <h3>...</h3>
              )}
            </article>
            <article className="detail-section">
              {currentOfflineRepayment && currentOfflineRepayment.portfolio && (
                <Fragment>
                  <h6>Personal Information</h6>
                  <ul>
                    <li>
                      <span>Contact Email</span>
                      <span>
                        {currentOfflineRepayment.portfolio.user.email}
                      </span>
                    </li>
                  </ul>

                  <h6>Portfolio Details</h6>
                  <ul>
                    <li>
                      <span>Loan ID</span>
                      <span>
                        {currentOfflineRepayment.portfolio.portfolioNumber}
                      </span>
                    </li>
                    <li>
                      <span>Full Amount</span>
                      <span>
                        {nairaAmount(
                          currentOfflineRepayment.portfolio.fullAmount,
                        )}
                      </span>
                    </li>
                    <li>
                      <span className="view-portfolio-btn">
                        <Link
                          to={`/loans/${currentOfflineRepayment.portfolio.portfolioNumber}/${currentOfflineRepayment.portfolio.user.email}`}
                        >
                          View Portfolio
                        </Link>
                      </span>
                      <span> </span>
                    </li>
                  </ul>
                </Fragment>
              )}
            </article>
          </CustomerDetailsSideBar>

          <main className="layout-customerdetails">
            <h5>Manual Repayment Details</h5>
            <div className="loans">
              <DataCard noPadding={false}>
                {approveOfflineRepayLoading ? (
                  <Loader loadingText="loading..." />
                ) : (
                  <Fragment>
                    <Toast
                      title="Approve Offline Repayment"
                      content={approveOfflineRepaymentData.content.value}
                      clickEvent={handleApproveRepayHideToast}
                      active={approveOfflineRepaymentData.showToast.value}
                      classes={approveOfflineRepaymentData.status.value}
                    />
                    <Modal
                      title="Approve Offline Repayment"
                      active={approveOfflineRepaymentData.showModal.value}
                    >
                      <Close
                        className="close-icon"
                        onClick={handleApproveRepayModalToggle}
                      />
                      <div className="actions">
                        <div className="flex-container center">
                          <div className="modal-button">
                            <Button
                              classes="secondary"
                              click_event={e =>
                                handleApproveOfflineRepayment(
                                  e,
                                  currentOfflineRepayment.id,
                                )
                              }
                            >
                              Approve
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </Fragment>
                )}

                {declineOfflineRepayLoading ? (
                  <Loader loadingText="loading..." />
                ) : (
                  <Fragment>
                    <Toast
                      title="Decline Offline Repayment"
                      content={declineOfflineRepaymentData.content.value}
                      clickEvent={handleDeclineRepayHideToast}
                      active={declineOfflineRepaymentData.showToast.value}
                      classes={declineOfflineRepaymentData.status.value}
                    />
                    <Modal
                      title="Decline Offline Repayment"
                      active={declineOfflineRepaymentData.showModal.value}
                    >
                      <Close
                        className="close-icon"
                        onClick={handleDeclineRepayModalToggle}
                      />
                      <div className="actions">
                        <div className="flex-container center">
                          <div className="modal-button">
                            <Button
                              classes="secondary"
                              click_event={e =>
                                handleDeclineOfflineRepayment(
                                  e,
                                  currentOfflineRepayment.id,
                                )
                              }
                            >
                              Decline
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </Fragment>
                )}
                {currentOfflineRepayment && (
                  <div className="information--content">
                    <Table>
                      <div className="table__body offline-repayment-table">
                        <ul>
                          <li className="table_line">
                            <span>Amount Paid</span>
                            <span className="right-text">
                              {nairaAmount(currentOfflineRepayment.amount)}
                            </span>
                          </li>

                          <li className="table_line">
                            <span>Payment Method</span>
                            <span className="right-text">
                              {currentOfflineRepayment.service}
                            </span>
                          </li>

                          {currentOfflineRepayment.bank && (
                            <li className="table_line">
                              <span>Bank Name</span>
                              <span className="right-text">
                                {currentOfflineRepayment.bank.name}
                              </span>
                            </li>
                          )}

                          {currentOfflineRepayment.metadata &&
                            Object.keys(currentOfflineRepayment.metadata).map(
                              (key, index) => (
                                <li
                                  className="table_line capitalize"
                                  key={index}
                                >
                                  <span>
                                    {insertSpaceBeforeCapitalLetter(key)}
                                  </span>
                                  <span className="right-text">
                                    {currentOfflineRepayment.metadata[key]}
                                  </span>
                                </li>
                              ),
                            )}

                          {currentOfflineRepayment.supportingDocument &&
                            currentOfflineRepayment.supportingDocument.length >
                              0 && (
                              <li className="table_line">
                                <span>Uploaded Evidence</span>
                                <span className="right-text">
                                  <UrlFromS3Provider
                                    file={
                                      currentOfflineRepayment
                                        .supportingDocument[0].file
                                    }
                                  >
                                    {({ exportFromS3, loading }) => (
                                      <div>
                                        <Button
                                          classes="link"
                                          click_event={exportFromS3}
                                          disabled={loading}
                                        >
                                          <FileIcon className="icon" />
                                          Evidence
                                        </Button>
                                      </div>
                                    )}
                                  </UrlFromS3Provider>
                                </span>
                              </li>
                            )}

                          {currentOfflineRepayment.createdAt && (
                            <li className="table_line">
                              <span>Repayment Date</span>
                              <span className="right-text">
                                {moment(
                                  currentOfflineRepayment.createdAt,
                                ).format('lll')}
                              </span>
                            </li>
                          )}

                          <li className="table_line">
                            <span>Outstanding Balance</span>
                            <span className="right-text">
                              {nairaAmount(
                                currentOfflineRepayment.portfolio.fullAmount -
                                  currentOfflineRepayment.portfolio.amountPaid,
                              )}
                            </span>
                          </li>

                          <li className="table_line">
                            <span>Status</span>
                            <span className="right-text">
                              {currentOfflineRepayment.status}
                            </span>
                          </li>

                          {currentOfflineRepayment &&
                            currentOfflineRepayment.status === 'PENDING' &&
                            currentOfflineRepayment.portfolio.status.name !==
                              'CLOSED' && (
                              <li className="table_line">
                                <span>Action</span>
                                <span className="right-text">
                                  <div className="buttons">
                                    <Button
                                      classes="secondary"
                                      click_event={
                                        handleApproveRepayModalToggle
                                      }
                                    >
                                      Approve
                                    </Button>
                                    <Button
                                      click_event={
                                        handleDeclineRepayModalToggle
                                      }
                                    >
                                      Decline
                                    </Button>
                                  </div>
                                </span>
                              </li>
                            )}
                        </ul>
                      </div>
                    </Table>
                  </div>
                )}
              </DataCard>
            </div>
          </main>
        </Fragment>
      )}
    </>
  );
};

export default ManualRepaymentDetails;
