import React, { Fragment } from 'react';
import {
  CustomerSummary,
  BreadCrumbSection,
  NotFound,
  Applications,
  Portfolios,
  Page,
  ActionableApplications,
  DataCard,
  EmptyScreen,
} from '../../components';
import { PortfolioSummaryProvider } from '../../Providers';
import { nairaAmount } from '../../lib/Utils';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

const Loans = () => (
  <>
    <Fragment>
      <BreadCrumbSection />
      <main className={'layout-main'}>
        <h5>Loans Summary</h5>

        <PortfolioSummaryProvider
          render={({ portfolioSummaryData }) => (
            <CustomerSummary title="Active Loans" classes={'four-grid'}>
              <div>
                <h5>Number of Active Loans</h5>
                <h3>
                  {portfolioSummaryData
                    ? portfolioSummaryData.portfolioSummary.numberOfActiveLoans
                    : 0}
                </h3>
              </div>
              <div className="mid">
                <h5>Sum Active Loans</h5>
                <h3>
                  {portfolioSummaryData
                    ? nairaAmount(
                        portfolioSummaryData.portfolioSummary.totalActiveLoans,
                      )
                    : 0}
                </h3>
              </div>
              <div className="right">
                <h5>Total Loans</h5>
                <h3>
                  {portfolioSummaryData
                    ? portfolioSummaryData.portfolioSummary.totalLoans
                    : 0}
                </h3>
              </div>
              <div className="right">
                <h5>Sum Total Loans</h5>
                <h3>
                  {portfolioSummaryData
                    ? nairaAmount(
                        portfolioSummaryData.portfolioSummary.sumTotalLoans,
                      )
                    : 0}
                </h3>
              </div>
            </CustomerSummary>
          )}
        />
      </main>
      <ErrorBoundary
        fallback={
          <DataCard noPadding={true} showDialog>
            <EmptyScreen message="Error Fetching transaction" />
          </DataCard>
        }
      >
        <Fragment>
          <Switch>
            <Route exact path="/">
              <Redirect to="/v1/applications" />
            </Route>

            <Route exact path="/applications">
              <Redirect to="v1/applications" />
            </Route>

            <Route
              exact
              path="/loans"
              render={props => (
                <Page {...props} component={Portfolios} title="Loans" />
              )}
            />

            <Route exact path="/loans/applications">
              <Redirect to="/v1/applications" />
            </Route>

            <Route
              exact
              path="/actionable-applications"
              render={props => (
                <Page
                  {...props}
                  component={ActionableApplications}
                  title="Loans"
                />
              )}
            />
            <Route
              exact
              path="/loans/actionable-applications"
              render={props => (
                <Page
                  {...props}
                  component={ActionableApplications}
                  title="Loans"
                />
              )}
            />

            <Route
              exact
              path=""
              render={props => (
                <Page {...props} component={NotFound} title="Not Found" />
              )}
            />
          </Switch>
        </Fragment>
      </ErrorBoundary>
    </Fragment>
  </>
);

export default Loans;
