import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { DECLINE_OFFLINE_REPAYMENT } from './queries';
import { useKeyPair } from '../Form';
import { trimGraphQLError } from '../../lib/Utils';
import { OFFLINE_REPAYMENTS_QUERY } from '../../graphql/queries';

const useMutateDeclineOfflineRepayment = () => {
  const data = {
    showToast: useKeyPair(false),
    showModal: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
  };

  const history = useHistory();

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 3500);
  };

  const handleModalToggle = () => {
    data.showModal.setValue(!data.showModal.value);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
    handleModalToggle();
  };

  const handleDeclineOfflineRepayment = async (e, repaymentId = {}) => {
    e.preventDefault();

    await approveOfflineRepayment({
      variables: {
        input: { id: repaymentId },
      },
      refetchQueries: () => [{ query: OFFLINE_REPAYMENTS_QUERY }],
    });
  };

  const [approveOfflineRepayment, { loading: declineOfflineRepayLoading }] =
    useMutation(DECLINE_OFFLINE_REPAYMENT, {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue('Offline Repayment Declined');
        data.showModal.setValue(false);

        handleHideToast();
        setTimeout(() => {
          history.push('/liquidity-management/manual-repayments');
        }, 1000);
      },
      onError,
    });

  return {
    handleDeclineRepayHideToast: handleHideToast,
    declineOfflineRepayLoading,
    declineOfflineRepaymentData: data,
    handleDeclineOfflineRepayment: (e, repaymentId) =>
      handleDeclineOfflineRepayment(e, repaymentId),
    handleDeclineRepayModalToggle: handleModalToggle,
  };
};

export { useMutateDeclineOfflineRepayment };
