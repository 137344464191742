import React from 'react';
import {
  CollectionsTableProvider,
  CollectionsWalletProvider,
} from '../../Providers';
import {
  Button,
  DataCard,
  EmptyScreen,
  Export,
  Loader,
  Table,
  Toast,
} from '../../components';
import {
  capitalizeInitialLetter,
  nairaAmount,
  reduceStringLength,
} from '../../lib/Utils';
import moment from 'moment';
import Search from './Search';
import { Link } from 'react-router-dom';
import LiquidityManagementNavs from './LiquidityManagementNavs';
import { ErrorBoundary } from 'react-error-boundary';

const headers = ['ID', 'Amount', 'Status', 'Type', 'Created At'];

const CollectionsWallet = () => {
  const {
    data,
    loading,
    input,
    handleHideToast,
    handleCollectionsWalletReset,
    searchQuery,
    handleCollectionsWalletStartDate,
    handleCollectionsWalletEndDate,
    handleExportToCsv,
    setFilterParams,
  } = CollectionsWalletProvider();

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="layout-main">
        <LiquidityManagementNavs />
        <Toast
          title="Liquidity Management"
          content={input.content.value}
          classes={input.status.value}
          active={input.showToast.value}
          clickEvent={handleHideToast}
        />
        <Search
          input={input}
          resetAction={handleCollectionsWalletReset}
          searchAction={searchQuery}
          type="Collections Wallet"
          handleStartDate={handleCollectionsWalletStartDate}
          handleEndDate={handleCollectionsWalletEndDate}
        />
        {Object.keys(data).length > 0 &&
        data.getCollections.account.wallets.feeWallet.transactions.nodes
          .length > 0 ? (
          <ErrorBoundary
            fallback={
              <DataCard noPadding={true}>
                <EmptyScreen message="Error Fetching transaction" />
              </DataCard>
            }
          >
            <div className="loans">
              <Button
                classes="right handle-export"
                click_event={handleExportToCsv}
              >
                <Export />
                <span className="export-csv">Export CSV</span>
              </Button>
              <CollectionsTableProvider
                setFilterParams={setFilterParams}
                render={({
                  isLoading,
                  handlePagination,
                  collectionsTableData,
                }) => (
                  <section>
                    <DataCard noPadding={false}>
                      <Table>
                        <div className="table__head">
                          {headers.map((header, idx) => (
                            <span key={idx}>{header}</span>
                          ))}
                        </div>
                        <div className="table__body">
                          {collectionsTableData &&
                            collectionsTableData.getCollections &&
                            collectionsTableData.getCollections.account.wallets.feeWallet.transactions.nodes.map(
                              (transaction, idx) => (
                                <ul key={idx}>
                                  <Link
                                    to={`/loans/${transaction.portfolio.portfolioNumber}/${transaction.portfolio.user.email}`}
                                  >
                                    <li>
                                      <span>
                                        <div className="first-td">
                                          {reduceStringLength(
                                            transaction.portfolio
                                              .portfolioNumber,
                                            15,
                                          ).toUpperCase()}
                                        </div>
                                      </span>
                                      <span>
                                        {nairaAmount(transaction.amount || 0)}
                                      </span>
                                      <span>
                                        {capitalizeInitialLetter(
                                          transaction.status,
                                        )}
                                      </span>
                                      <span>{transaction.type.name}</span>
                                      <span>
                                        {moment(transaction.createdAt).format(
                                          'lll',
                                        )}
                                      </span>
                                    </li>
                                  </Link>
                                </ul>
                              ),
                            )}
                        </div>
                      </Table>
                    </DataCard>

                    {collectionsTableData.getCollections.account.wallets
                      .feeWallet.transactions.pageInfo.hasNextPage &&
                      (isLoading ? (
                        <Loader />
                      ) : (
                        <Button
                          classes={'secondary center activate-policy'}
                          click_event={handlePagination}
                        >
                          Load more
                        </Button>
                      ))}
                  </section>
                )}
              />
            </div>
          </ErrorBoundary>
        ) : (
          <DataCard noPadding={true}>
            <EmptyScreen message="No Transactions Yet" />
          </DataCard>
        )}
      </div>
    </>
  );
};

export default CollectionsWallet;
