import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { NotFound, Page } from '..';
import {
  ApplicationDetails,
  ApprovalWorkflows,
  Configurations,
  CreateCustomApprovalWorkflow,
  CreateLoanGroup,
  CreateLoanProduct,
  CreateNewRule,
  CreatePolicy,
  CreateRole,
  CreateUser,
  CustomerDetails,
  Customers,
  EditUser,
  Facilities,
  FacilityDetails,
  LiquidityManagement,
  LoanGroups,
  LoanRules,
  Loans,
  Logout,
  Policy,
  PolicyDetails,
  PortfolioDetails,
  Roles,
  Settings,
  Tasks,
  UserDetails,
  UserManagement,
  WebHooks,
} from '../../containers';
import ManualRepaymentDetails from '../../containers/LiquidityManagement/ManualRepaymentDetails';
import MetabaseAccountsReport from '../../containers/Reports/MetabaseAccountsReport';
import MetabaseApplicationsReport from '../../containers/Reports/MetabaseApplicationsReport';
import MetabaseLoansReport from '../../containers/Reports/MetabaseLoansReport';
import MetabaseRepaymentsReport from '../../containers/Reports/MetabaseRepaymentsReport';
import Favicons from '../Head/Favicon';
import Header from '../Header/Header';
import ProdCamp from '../ProdCamp/ProdCamp';
import './_Layout.scss';
import { getUserFullDetails, hasPermissions } from '../../containers/Config';
import {
  VIEW_ACCOUNTS_REPORT,
  VIEW_APPLICATIONS_REPORT,
  VIEW_LOANS_REPORT,
  VIEW_REPAYMENTS_REPORT,
} from '../../containers/Config/constants';
import KycConfigurations from '../../containers/UserManagement/KycConfiguations';
import { Dashboard } from '../../v1/routes/dashboard';
import NewCustomer from '../../v1/routes/customers/routes/newCustomer/NewCustomer';

const AuthenticatedLayout = () => {
  const userDetails = getUserFullDetails();
  const userPermissions = userDetails.permissions || [];

  return (
    localStorage.getItem('api-key') !== null && (
      <section className="layout">
        <Favicons />
        <Header />
        <Switch>
          <Route
            exact
            path="/dashboard"
            render={props => (
              <Page {...props} component={Dashboard} title="Dashboard" />
            )}
          />

          <Route
            exact
            path="/"
            render={props => (
              <Page {...props} component={Loans} title="Loans" />
            )}
          />

          <Route
            exact
            path="/signup"
            render={props => (
              <Page {...props} component={Loans} title="Loans" />
            )}
          />
          <Route
            exact
            path="/loans"
            render={props => (
              <Page {...props} component={Loans} title="Loans" />
            )}
          />
          <Route
            exact
            path="/applications"
            render={props => (
              <Page {...props} component={Loans} title="Loans" />
            )}
          />
          <Route
            exact
            path="/actionable-portfolios"
            render={props => (
              <Page {...props} component={Loans} title="Loans" />
            )}
          />
          <Route
            exact
            path="/actionable-applications"
            render={props => (
              <Page {...props} component={Loans} title="Loans" />
            )}
          />
          <Route
            exact
            path="/loans/:subPage"
            render={props => (
              <Page {...props} component={Loans} title="Loans" />
            )}
          />
          <Route
            path="/loans/:portfolioNumber/:customerEmail"
            render={props => (
              <Page
                {...props}
                component={PortfolioDetails}
                title="Portfolio Details"
              />
            )}
          />
          <Route
            path="/applications/:applicationNumber/:customerEmail"
            render={props => (
              <Page
                {...props}
                component={ApplicationDetails}
                title="Application Details"
              />
            )}
          />
          <Route
            exact
            path="/customers"
            render={props => (
              <Page {...props} component={Customers} title="Customers" />
            )}
          />
          <Route
            exact
            path="/tasks"
            render={props => (
              <Page {...props} component={Tasks} title="Tasks" />
            )}
          />
          <Route
            path="/customers/:name"
            render={props => (
              <Page
                {...props}
                component={CustomerDetails}
                title="Customer Details"
              />
            )}
          />
          <Route
            exact
            path="/customers/new-customer"
            render={props => (
              <Page {...props} component={NewCustomer} title="New Customer" />
            )}
          />
          <Route
            exact
            path="/facilities"
            render={props => (
              <Page {...props} component={Facilities} title="Facilities" />
            )}
          />
          <Route
            exact
            path="/tasks"
            render={props => (
              <Page {...props} component={Tasks} title="Tasks" />
            )}
          />
          <Route
            path="/facilities/:name"
            render={props => (
              <Page
                {...props}
                component={FacilityDetails}
                title="Facility Details"
              />
            )}
          />
          <Route
            exact
            path="/liquidity-management"
            render={props => (
              <Page
                {...props}
                component={LiquidityManagement}
                title="Liquidity Management"
              />
            )}
          />
          <Route
            exact
            path="/liquidity-management/:subPage"
            render={props => (
              <Page
                {...props}
                component={LiquidityManagement}
                title="Liquidity Management"
              />
            )}
          />
          <Route
            path="/liquidity-management/manual-repayments/:id"
            render={props => (
              <Page
                {...props}
                component={ManualRepaymentDetails}
                title="Manual Repayment Details"
              />
            )}
          />
          <Route
            exact
            path="/loan-products"
            render={props => (
              <Page {...props} component={Policy} title="Loan Products" />
            )}
          />
          <Route
            path="/loan-products/update"
            render={props => (
              <Page
                {...props}
                component={CreateLoanProduct}
                title="Edit Loan Product"
              />
            )}
          />
          <Route
            exact
            path="/policy/create-policy"
            render={props => (
              <Page {...props} component={CreatePolicy} title="Create Policy" />
            )}
          />
          <Route
            exact
            path="/loan-products/create-loan-product"
            render={props => (
              <Page
                {...props}
                component={CreateLoanProduct}
                title="Create Loan Product"
              />
            )}
          />
          <Route
            exact
            path="/loan-products/:policyId"
            render={props => (
              <Page
                {...props}
                component={PolicyDetails}
                title="Policy Details"
              />
            )}
          />
          <Route
            exact
            path="/loan-categories"
            render={props => (
              <Page {...props} component={LoanGroups} title="Loan Categories" />
            )}
          />
          <Route
            exact
            path="/loan-categories/create-loan-category"
            render={props => (
              <Page
                {...props}
                component={CreateLoanGroup}
                title="Create Loan Category"
              />
            )}
          />
          <Route
            path="/loan-categories/update/:categoryName"
            render={props => (
              <Page
                {...props}
                component={CreateLoanGroup}
                title="Edit Loan Category"
              />
            )}
          />
          <Route
            exact
            path="/settings"
            render={props => (
              <Page {...props} component={Settings} title="Settings" />
            )}
          />
          <Route
            exact
            path="/settings/user-management"
            render={props => (
              <Page
                {...props}
                component={UserManagement}
                title="User Management"
              />
            )}
          />
          <Route
            exact
            path="/settings/user-management/:email"
            render={props => (
              <Page
                {...props}
                component={UserDetails}
                title="User Management"
              />
            )}
          />
          <Route
            exact
            path="/settings/create-user"
            render={props => (
              <Page {...props} component={CreateUser} title="Create User" />
            )}
          />
          <Route
            exact
            path="/edit-user/:userId/:userEmail"
            render={props => (
              <Page {...props} component={CreateUser} title="Edit User" />
            )}
          />
          <Route
            exact
            path="/settings/approval-workflows"
            render={props => (
              <Page
                {...props}
                component={ApprovalWorkflows}
                title="Approval Workflows"
              />
            )}
          />
          <Route
            path="/settings/approval-workflows/create-custom-workflow"
            render={props => (
              <Page
                {...props}
                component={CreateCustomApprovalWorkflow}
                title="Create Custom Workflow"
              />
            )}
          />
          <Route
            path="/settings/approval-workflows/:workflowId"
            render={props => (
              <Page
                {...props}
                component={CreateCustomApprovalWorkflow}
                title="Edit Custom Workflow"
              />
            )}
          />
          <Route
            exact
            path="/settings/edit-user"
            render={props => (
              <Page {...props} component={EditUser} title="Edit User" />
            )}
          />
          <Route
            exact
            path="/settings/roles"
            render={props => (
              <Page {...props} component={Roles} title="Roles" />
            )}
          />
          <Route
            exact
            path="/settings/roles/create-new-role"
            render={props => (
              <Page {...props} component={CreateRole} title="Create New Role" />
            )}
          />
          <Route
            exact
            path="/settings/loan-rules"
            render={props => (
              <Page {...props} component={LoanRules} title="Loan Rules" />
            )}
          />
          <Route
            exact
            path="/settings/loan-rules/create"
            render={props => (
              <Page
                {...props}
                component={CreateNewRule}
                title="Create New Rule"
              />
            )}
          />
          <Route
            path="/settings/loan-rules/:loanRuleName"
            render={props => (
              <Page
                {...props}
                component={CreateNewRule}
                title="Edit Loan Rule"
              />
            )}
          />
          <Route
            exact
            path="/settings/api-keys-webhooks"
            render={props => (
              <Page
                {...props}
                component={WebHooks}
                title="API Keys & Webhooks"
              />
            )}
          />

          <Route
            exact
            path="/settings/configurations"
            render={props => (
              <Page
                {...props}
                component={Configurations}
                title="Configurations"
              />
            )}
          />

          <Route
            exact
            path="/settings/kyc-configurations"
            render={props => (
              <Page
                {...props}
                component={KycConfigurations}
                title="Kyc Configurations"
              />
            )}
          />

          {hasPermissions(VIEW_APPLICATIONS_REPORT, userPermissions) && (
            <Route
              exact
              path="/reports"
              render={props => (
                <Page
                  {...props}
                  component={MetabaseApplicationsReport}
                  title="Applications Report"
                />
              )}
            />
          )}
          {hasPermissions(VIEW_LOANS_REPORT, userPermissions) && (
            <Route
              exact
              path="/reports/loans"
              render={props => (
                <Page
                  {...props}
                  component={MetabaseLoansReport}
                  title="Loans Report"
                />
              )}
            />
          )}
          {hasPermissions(VIEW_REPAYMENTS_REPORT, userPermissions) && (
            <Route
              exact
              path="/reports/repayments"
              render={props => (
                <Page
                  {...props}
                  component={MetabaseRepaymentsReport}
                  title="Repayments Report"
                />
              )}
            />
          )}
          {hasPermissions(VIEW_ACCOUNTS_REPORT, userPermissions) && (
            <Route
              exact
              path="/reports/accounts"
              render={props => (
                <Page
                  {...props}
                  component={MetabaseAccountsReport}
                  title="Accounts Report"
                />
              )}
            />
          )}

          <Route
            path="/logout"
            exact
            render={props => (
              <Page {...props} component={Logout} title="Logout" />
            )}
          />
          <Route
            path=""
            exact
            render={props => (
              <Page {...props} component={NotFound} title="Page Not Found" />
            )}
          />
        </Switch>

        <ProdCamp />
      </section>
    )
  );
};

export default withRouter(AuthenticatedLayout);
