import { graphql } from '@/__generated/gql';

export const APPLICATION_DETAILS = graphql(/* GraphQL */ `
  query ApplicationDetails($applicationNumber: String!) {
    application(applicationNumber: $applicationNumber) {
      id
      applicationNumber
      amount
      source
      fullAmount
      processingFee
      chargesAmount
      customApplicationForm
      requestedAmount
      previousApplications {
        totalCount
        pageInfo {
          endCursor
          startCursor
        }
        nodes {
          id
          applicationNumber
          total
          createdAt
          status {
            name
          }
          interest
          amount
          duration
        }
      }


      repaymentAmount {
        isBadge
        level
        value
      }

      policy {
        id
        name
        durationType
        maxLoanAmount
        minLoanAmount
        maxLoanDuration
        minLoanDuration
      }

      portfolio {
        id
        portfolioNumber
        status {
          name
        }
        repayments {
          id
          dueDate
          repaymentDate
          principalPortion
          interestPortion
          totalPayment
          status {
            name
          }
        }
      }

      loanDuration
      createdAt
      submittedAt

      moratoriumData {
        repaymentType
        numberOfPeriods
      }

      assignedTo {
        accountUserId
        firstName
        lastName
      }

      bankAccount {
        id
        accountName
        accountNumber

        bank {
          id
          name
        }
      }

      user {
        id
        phone
        email
        lastName
        firstName

        userMetadata {
          id
          name
          value
        }

        bvnStatus {
          image
        }

        blacklistDetails {
          blacklisted
          isInternalBlacklist
        }

        supportingDocument {
          documentName
          file {
            bucket
            key
          }
        }
      }

      status {
        name
      }

      requiredSteps
      completedSteps

      account {
        id
        name
        bankAccounts {
          id
          bank {
            name
          }
          accountNumber
          accountName
        }
      }

      approvalWorkflow {
        id
        workflow {
          name
          id
        }
        nextStep {
          approvalType
          orderNo
        }
      }

      uploadedBankStatement {
        url
        password
      }

      credit {
        bankStatement
        crcMicroScoreReport
        crcScoreReport
        crcJsonScoreReport
        creditBureauScoreReport
        creditRegistryReport
        decideBankStatementAnalysis
        okraSummary {
          availableBalance
          totalDebit
          totalCredit
          accountName
          accountNumber
          bankName
          transactions
        }
        salaryDay
        score
        scoreCardResult
        modelResults {
          name
          modelResponse
          bankStatementAnalysis
        }
      }

      histories {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        nodes {
          id
          comment
          actionBy {
            id
            firstName
            lastName
          }
          createdAt
          newStatus {
            name
          }
          oldStatus {
            name
          }
        }
      }

      traces {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        nodes {
          id
          createdAt
          comment
          page
        }
      }

      meta {
        key
        document {
          documentName
          file {
            bucket
            key
          }
        }
      }

      reviewFeedbacks {
        id
        status
        reviewType
        documentRequests {
          id
          title
          message
          document {
            id
            fileUrl
            documentName
            file {
              key
              bucket
            }
          }
        }
      }
    }
  }
`);
