import { useApolloClient } from '@apollo/react-hooks';
import JwtDecode from 'jwt-decode';
import React, { Fragment, lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import {
  AuthenticatedLayout,
  Layout,
  Loader,
  NotFound,
  Page,
  PreLoginRoute,
  ProtectedRoute,
} from '../../components';
import config from '../../config/config';
import './_app.scss';
import { OriginateClient } from '../../Providers';
const V1Index = lazy(() => import('../../v1/V1Index'));

const App = () => {
  const client = useApolloClient();
  const sessionToken = localStorage.getItem('api-key');

  useEffect(() => {
    if (
      config.web.node_env === 'staging' ||
      config.web.node_env === 'production'
    ) {
      if (window.location.protocol !== 'https:') {
        window.location.href = `https:${window.location.href.substring(
          window.location.protocol.length,
        )}`;
      }
    }

    const sessionToken = localStorage.getItem('api-key');
    if (sessionToken) {
      clearUserTokenAndLogout();
    }
  });

  const clearUserTokenAndLogout = async () => {
    const expiryDate = JwtDecode(sessionToken).exp;
    const currentTime = new Date().getTime() / 1000;

    if (currentTime > expiryDate) {
      window.location.href = '/';
      localStorage.clear();
      await client.resetStore();
    }
  };

  return (
    <Fragment>
      <BrowserRouter>
        <OriginateClient
          render={() => {
            return (
              <Switch>
                <ProtectedRoute
                  exact
                  path="/"
                  component={AuthenticatedLayout}
                />
                <Redirect exact={true} from={'/loans'} to={'/v1/loans'} />
                <Redirect
                  exact={true}
                  from={'/applications'}
                  to={'/v1/applications'}
                />
                <ProtectedRoute
                  exact
                  path="/actionable-portfolios"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  exact
                  path="/actionable-applications"
                  component={AuthenticatedLayout}
                />
                <Redirect
                  exact={true}
                  from={'/customers'}
                  to={'/v1/customers'}
                />

                <ProtectedRoute
                  path="/facilities"
                  component={AuthenticatedLayout}
                />
                {/* @deprecated route */}
                {/* <ProtectedRoute
                  path="/loan-products"
                  component={AuthenticatedLayout}
                /> */}

                <ProtectedRoute
                  path="/loan-products/:policyId"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/policy/create-policy"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/loan-products/create-loan-product"
                  component={AuthenticatedLayout}
                />
                {/* @deprecated route */}
                {/* <ProtectedRoute
                  path="/loan-categories"
                  component={AuthenticatedLayout}
                /> */}
                <ProtectedRoute
                  path="/loan-categories/create-loan-category"
                  component={AuthenticatedLayout}
                />
                {/* @deprecated route */}
                {/* <ProtectedRoute
                  path="/customers/:name"
                  component={AuthenticatedLayout}
                /> */}

                <Redirect
                  exact={true}
                  from={'/customers/:name'}
                  to={'/v1/customers/:name'}
                />

                <ProtectedRoute
                  path="v1/customers/new-customer"
                  component={AuthenticatedLayout}
                />

                <ProtectedRoute
                  path="/facilities/:name"
                  component={AuthenticatedLayout}
                />
                {/* @deprecated route */}
                {/* <ProtectedRoute path="/tasks" component={AuthenticatedLayout} /> */}
                <ProtectedRoute
                  path="/loans/:portfolioNumber/:customerEmail"
                  component={AuthenticatedLayout}
                />
                <Redirect
                  exact={true}
                  from={'/applications/:applicationNumber/:customerEmail'}
                  to={'/v1/applications/:applicationNumber'}
                />
                <Redirect
                  exact={true}
                  from={'/applications/:applicationNumber'}
                  to={'/v1/applications/:applicationNumber'}
                />
                <ProtectedRoute
                  path="/liquidity-management"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/liquidity-management/:subPage"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/liquidity-management/manual-repayments/:id"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/user-management"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/user-management/:email"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/settings"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/create-user"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/loan-rules"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/loan-rules/create"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/loan-rules/:loanRuleId"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/loan-products/update"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/edit-user"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/approval-workflows"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/approval-workflows/create-custom-workflow"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/approval-workflows/:workflowId"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/loan-categories/update/:categoryName"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/reports"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/reports/metabase"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/reports/metabase/repayments"
                  component={AuthenticatedLayout}
                />
                <ProtectedRoute
                  path="/logout"
                  component={AuthenticatedLayout}
                />
                <PreLoginRoute exact path="/" component={AuthenticatedLayout} />
                <Redirect to={'/v1/login'} from={'/login'} exact={true} />
                <PreLoginRoute path="/accept-invite" component={Layout} />
                <PreLoginRoute
                  path="/trigger-reset-password"
                  component={Layout}
                />
                <PreLoginRoute
                  path="/reset-password/:resetToken"
                  component={Layout}
                />
                <PreLoginRoute
                  path="/reset-password-confirmation"
                  component={Layout}
                />
                <PreLoginRoute path="/new-password" component={Layout} />
                <PreLoginRoute
                  path="/password-reset-complete"
                  component={Layout}
                />

                <Route
                  path="/v1/*"
                  render={props => (
                    <Suspense fallback={<Loader />}>
                      <V1Index {...props} />
                    </Suspense>
                  )}
                />

                <Route
                  path="*"
                  exact
                  render={props => (
                    <Page
                      {...props}
                      component={NotFound}
                      title="Page not found"
                    />
                  )}
                />
              </Switch>
            );
          }}
        />
      </BrowserRouter>
    </Fragment>
  );
};

export default App;
