import { useQuery } from '@apollo/react-hooks';
import { OFFLINE_REPAYMENTS_QUERY } from '../../graphql/queries';

const useOfflineRepayment = ({ variables }) => {
  const qResult = useQuery(OFFLINE_REPAYMENTS_QUERY, {
    variables,
    fetchPolicy: 'network-only',
  });
  const { data, loading, error } = qResult;

  return {
    loading,
    error,
    offlineRepayments: loading || !data ? [] : data.offlineRepayments.nodes,
    result: qResult,
  };
};

export { useOfflineRepayment };
