import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  DataCard,
  EmptyScreen,
  Export,
  Loader,
  Table,
  Toast,
} from '../../components';
import {
  capitalizeInitialLetter,
  nairaAmount,
  reduceStringLength,
  subtractColumn,
} from '../../lib/Utils';
import {
  InterestsTableProvider,
  InterestWalletProvider,
} from '../../Providers';
import Search from './Search';
import LiquidityManagementNavs from './LiquidityManagementNavs';
import { ErrorBoundary } from 'react-error-boundary';

const headers = ['ID', 'Interest', 'Status', 'Type', 'Repayment Date'];

const InterestWallet = () => {
  const {
    data,
    loading,
    input,
    handleHideToast,
    handleFeeWalletReset,
    searchQuery,
    handleFeeWalletStartDate,
    handleFeeWalletEndDate,
    handleExportToCsv,
    setFilterParams,
  } = InterestWalletProvider();
  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="layout-main">
        <LiquidityManagementNavs />
        <Toast
          title="Liquidity Management"
          content={input.content.value}
          classes={input.status.value}
          active={input.showToast.value}
          clickEvent={handleHideToast}
        />
        <Search
          input={input}
          resetAction={handleFeeWalletReset}
          searchAction={searchQuery}
          type="Interest Wallet"
          handleStartDate={handleFeeWalletStartDate}
          handleEndDate={handleFeeWalletEndDate}
        />
        {data &&
        Object.keys(data).length > 0 &&
        data.getInterestWallets.account.wallets.feeWallet.transactions.nodes
          .length > 0 ? (
          <ErrorBoundary
            fallback={
              <DataCard noPadding={true}>
                <EmptyScreen message="Error Fetching transaction" />
              </DataCard>
            }
          >
            <div className="loans">
              <Button
                classes="right handle-export"
                click_event={handleExportToCsv}
              >
                <Export />
                <span className="export-csv">Export CSV</span>
              </Button>
              <InterestsTableProvider
                setFilterParams={setFilterParams}
                render={({
                  isLoading,
                  handlePagination,
                  interestsTableData,
                }) => (
                  <section>
                    <DataCard noPadding={false}>
                      <Table>
                        <div className="table__head">
                          {headers.map((header, idx) => (
                            <span key={idx}>{header}</span>
                          ))}
                        </div>
                        <div className="table__body">
                          {interestsTableData &&
                            interestsTableData.getInterestWallets &&
                            interestsTableData.getInterestWallets.account.wallets.feeWallet.transactions.nodes
                              .filter(
                                (item, index, newArray) =>
                                  index ===
                                  newArray.findIndex(
                                    t =>
                                      t.portfolio.portfolioNumber ===
                                      item.portfolio.portfolioNumber,
                                  ),
                              )
                              .map((transaction, idx) => (
                                <ul key={idx}>
                                  <Link
                                    to={`/loans/${transaction.portfolio.portfolioNumber}/${transaction.portfolio.user.email}`}
                                  >
                                    <li>
                                      <span>
                                        <div className="first-td">
                                          {reduceStringLength(
                                            transaction.portfolio
                                              .portfolioNumber,
                                            15,
                                          ).toUpperCase()}
                                        </div>
                                      </span>
                                      {transaction.portfolio.repayments
                                        .length !== 0 ? (
                                        <span>
                                          {nairaAmount(
                                            subtractColumn(
                                              transaction.portfolio.repayments,
                                              'interestPortion',
                                              'outstandingPayment',
                                            ),
                                          )}
                                        </span>
                                      ) : (
                                        <span>
                                          {nairaAmount(
                                            transaction.portfolio
                                              .chargesAmount || 0,
                                          )}
                                        </span>
                                      )}
                                      <span>
                                        {capitalizeInitialLetter(
                                          transaction.status,
                                        )}
                                      </span>
                                      <span>{`Interest`}</span>
                                      <span>
                                        {moment(transaction.createdAt).format(
                                          'lll',
                                        )}
                                      </span>
                                    </li>
                                  </Link>
                                </ul>
                              ))}
                        </div>
                      </Table>
                    </DataCard>

                    {interestsTableData.getInterestWallets.account.wallets
                      .feeWallet.transactions.pageInfo.hasNextPage &&
                      (isLoading ? (
                        <Loader />
                      ) : (
                        <Button
                          classes={'secondary center activate-policy'}
                          click_event={handlePagination}
                        >
                          Load more
                        </Button>
                      ))}
                  </section>
                )}
              />
            </div>
          </ErrorBoundary>
        ) : (
          <DataCard noPadding={true}>
            <EmptyScreen message="No Transactions Yet" />
          </DataCard>
        )}
      </div>
    </>
  );
};

export default InterestWallet;
