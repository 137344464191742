import { useQuery } from '@apollo/react-hooks';
import { CUSTOMERS_QUERY, OFFLINE_REPAYMENTS_QUERY } from '../graphql/queries';
import { useCustomState } from '../lib/CustomHooks';
import Constants from '../lib/Constants';
import { deepCopy, exportToCsv } from '../lib/Utils';
import { connection_limit } from '../config/config';
import { useParams } from 'react-router-dom';

const OfflineRepaymentsProvider = () => {
  let { id } = useParams();

  const { data: customerData } = useQuery(CUSTOMERS_QUERY, {
    variables: {},
    fetchPolicy: 'network-only',
  });

  let customerName;

  if (customerData && customerData.viewer) {
    customerName = customerData.viewer.account.customers.nodes[0].name;
  }

  let exportCsvData = {};

  const offlineRepaymentsInput = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    searchParams: {
      id: useCustomState(),
      name: useCustomState(),
      status: useCustomState(),
      isSearchPerformed: useCustomState(false),
    },
  };

  const setSearchParams = searchParams => {
    const { id, status, name } = searchParams;
    return {
      id: id.value ? id.value : undefined,
      status: status.value ? status.value : undefined,
      name: name.value ? name.value : undefined,
      first: undefined,
    };
  };

  const GetExportCsvData = () => {
    const { searchParams } = offlineRepaymentsInput;
    let isSearchPerformed =
      offlineRepaymentsInput.searchParams.isSearchPerformed.value || false;

    const response = useQuery(OFFLINE_REPAYMENTS_QUERY, {
      fetchPolicy: 'no-cache',
      variables: isSearchPerformed ? setSearchParams(searchParams) : null,
    });

    if (response && response.data && response.data.offlineRepayments) {
      exportCsvData = response.data;
    }
  };

  GetExportCsvData();

  const handleHideToast = () => {
    setTimeout(() => {
      offlineRepaymentsInput.showToast.setValue(false);
    }, 2500);
  };

  const handleRefetch = async (e, refetch) => {
    e.preventDefault();

    offlineRepaymentsInput.searchParams.isSearchPerformed.setValue(true);
    reFetch(refetch, offlineRepaymentsInput.searchParams);
  };

  const handleReset = async (e, reFetch) => {
    e.preventDefault();

    const { searchParams } = offlineRepaymentsInput;

    searchParams.id.setValue('');
    searchParams.name.setValue('');
    searchParams.status.setValue('');
    searchParams.isSearchPerformed.setValue(false);

    reFetch({
      id: undefined,
      name: undefined,
      status: undefined,
      first: connection_limit.value,
    });
  };

  const reFetch = async (refetch, searchParams) => {
    refetch(setSearchParams(searchParams));
  };

  const handleExportToCsv = () => {
    if (!exportCsvData) {
      offlineRepaymentsInput.showToast.setValue(true);
      offlineRepaymentsInput.status.setValue('Failed');
      offlineRepaymentsInput.content.setValue(
        Constants.errorMessages.NO_EXPORT_DATA,
      );

      setTimeout(() => {
        offlineRepaymentsInput.showToast.setValue(false);
      }, 2000);
    }

    const {
      offlineRepayments: { nodes },
    } = exportCsvData;
    let csvData = deepCopy(nodes);
    let csvName = Constants.csvNames.MANUAL_REPAYMENTS;

    const filterCsvData = () => {
      const newCsvData = [];
      for (let node of csvData) {
        const items = {};

        items.Loan_ID = node.portfolio.portfolioNumber;
        items.Applicant_Name = `${node.user.firstName} ${node.user.lastName}`;
        items.Amount_Repaid = node.portfolio.amountPaid;
        items.Outstanding_Balance =
          node.portfolio.fullAmount - node.portfolio.amountPaid;
        items.Status = node.status ? node.status : '';

        newCsvData.push(items);
      }
      csvData = newCsvData;
    };
    filterCsvData();

    exportToCsv(csvData, csvName);
  };

  const { data, loading, error, refetch } = useQuery(OFFLINE_REPAYMENTS_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      first: connection_limit.value,
    },
  });
  if (error) {
    offlineRepaymentsInput.showToast.setValue(true);
    offlineRepaymentsInput.status.setValue('Failed');
    offlineRepaymentsInput.content.setValue(
      Constants.errorMessages.NETWORK_ERROR,
    );

    handleHideToast();
  }

  return {
    data,
    loading,
    input: offlineRepaymentsInput,
    handleHideToast: e => handleHideToast(e),
    handleSearchQuery: e => handleRefetch(e, refetch),
    handleReset: e => handleReset(e, refetch),
    handleExportToCsv: e => handleExportToCsv(e, data),
    customerName,
    offlineRepaymentId: id,
    setFilterParams: setSearchParams,
  };
};

export default OfflineRepaymentsProvider;
