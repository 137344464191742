import { useMutation } from '@apollo/react-hooks';
import { UPDATE_ATTRIBUTES } from './queries';
import { useFormInput, useKeyPair } from '../Form';
import { trimGraphQLError } from '../../lib/Utils';
import { useEffect } from 'react';
import { useClientInfo } from '../Client';
import { useGetAttributesBySlug } from './useGetAttributesBySlug';

const useUpdateNotificationConfig = () => {
  const sessionToken = localStorage.getItem('api-key');
  let isDisabled = false;
  const data = {
    showToast: useKeyPair(false),
    showModal: useKeyPair(false),
    status: useKeyPair(),
    content: useKeyPair(),
  };

  const inputs = {
    merchantApplicationApproved: useFormInput(),
    merchantApplicationDenied: useFormInput(),
    merchantApplicationReview: useFormInput(),
    merchantLoanDisbursed: useFormInput(),
    merchantLoanRepayment: useFormInput(),
    emailVerificationCode: useFormInput(),
  };

  const payload = {
    notificationAddress: {
      MERCHANT_APPLICATION_APPROVED:
        inputs.merchantApplicationApproved.value.trim(),
      MERCHANT_APPLICATION_DENIED:
        inputs.merchantApplicationDenied.value.trim(),
      MERCHANT_APPLICATION_REVIEW:
        inputs.merchantApplicationReview.value.trim(),
      MERCHANT_LOAN_DISBURSED: inputs.merchantLoanDisbursed.value.trim(),
      MERCHANT_LOAN_REPAYMENT: inputs.merchantLoanRepayment.value.trim(),
    },
    enableEmailVerificationCode:
      inputs.emailVerificationCode.value == false ? 'false' : 'true',
  };

  if (
    !payload.notificationAddress.MERCHANT_APPLICATION_APPROVED ||
    !payload.notificationAddress.MERCHANT_APPLICATION_DENIED ||
    !payload.notificationAddress.MERCHANT_APPLICATION_REVIEW ||
    !payload.notificationAddress.MERCHANT_LOAN_DISBURSED ||
    !payload.notificationAddress.MERCHANT_LOAN_REPAYMENT ||
    !payload.enableEmailVerificationCode
  ) {
    isDisabled = true;
  }

  const handleHideToast = () => {
    setTimeout(() => {
      data.showToast.setValue(false);
    }, 3500);
  };

  const onError = error => {
    data.showToast.setValue(true);
    data.status.setValue('Failed');
    data.content.setValue(trimGraphQLError(error.message));

    handleHideToast();
  };

  const { merchantAttributes, loading: ciLoading } = useGetAttributesBySlug();

  const { clientInfo, loading: cisLoading } = useClientInfo({
    privateFields: false,
  });

  useEffect(() => {
    const notificationAddressInfo = merchantAttributes?.find(
      item => item?.attribute?.name === 'notificationAddress',
    );
    const enableEmailVerificationCode = merchantAttributes?.find(
      item => item?.attribute?.name === 'enableEmailVerificationCode',
    );

    const setInputValues = () => {
      if (
        notificationAddressInfo &&
        notificationAddressInfo?.data?.MERCHANT_APPLICATION_APPROVED
      ) {
        inputs.merchantApplicationApproved.setValue(
          notificationAddressInfo?.data?.MERCHANT_APPLICATION_APPROVED,
        );
      }

      if (
        notificationAddressInfo &&
        notificationAddressInfo?.data?.MERCHANT_APPLICATION_DENIED
      ) {
        inputs.merchantApplicationDenied.setValue(
          notificationAddressInfo?.data?.MERCHANT_APPLICATION_DENIED,
        );
      }

      if (
        notificationAddressInfo &&
        notificationAddressInfo?.data?.MERCHANT_APPLICATION_REVIEW
      ) {
        inputs.merchantApplicationReview.setValue(
          notificationAddressInfo?.data?.MERCHANT_APPLICATION_REVIEW,
        );
      }

      if (
        notificationAddressInfo &&
        notificationAddressInfo?.data?.MERCHANT_LOAN_DISBURSED
      ) {
        inputs.merchantLoanDisbursed.setValue(
          notificationAddressInfo?.data?.MERCHANT_LOAN_DISBURSED,
        );
      }

      if (
        notificationAddressInfo &&
        notificationAddressInfo?.data?.MERCHANT_LOAN_REPAYMENT
      ) {
        inputs.merchantLoanRepayment.setValue(
          notificationAddressInfo?.data?.MERCHANT_LOAN_REPAYMENT,
        );
      }

      if (enableEmailVerificationCode && enableEmailVerificationCode?.value) {
        inputs.emailVerificationCode.setValue(
          enableEmailVerificationCode?.value == 'false' ? false : true,
        );
      }
    };
    setInputValues();
  }, [merchantAttributes]);

  const handleUpdateNotificationAttributes = async e => {
    e.preventDefault();

    await requestUpdateNotificationAttributes({
      variables: {
        input: {
          accountId: clientInfo.clientId,
          ...payload,
        },
        token: sessionToken,
      },
    });
  };

  const [requestUpdateNotificationAttributes, { loading }] = useMutation(
    UPDATE_ATTRIBUTES,
    {
      async onCompleted() {
        data.showToast.setValue(true);
        data.status.setValue('Success');
        data.content.setValue(
          'Updated Merchant Notification Attribute Successfully',
        );
        data.showModal.setValue(false);

        handleHideToast();
      },
      onError,
    },
  );

  return {
    handleUpdateNotificationAttributes,
    loading,
    ciLoading,
    clientInfo,
    inputs,
    data,
    isDisabled,
    handleHideToast,
  };
};

export { useUpdateNotificationConfig };
