import { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { connection_limit } from '../config/config';
import { INTEREST_TRANSACTIONS_QUERY } from '../graphql/queries';
import Constants from '../lib/Constants';
import { useCustomState } from '../lib/CustomHooks';
import { deepCopy, exportToCsv, trimGraphQLError } from '../lib/Utils';
import { toast } from 'react-toastify';

const InterestWalletProvider = () => {
  const liquidityManagementInput = {
    showToast: useCustomState(false),
    status: useCustomState(),
    content: useCustomState(),
    searchParams: {
      feeWalletId: useCustomState(),
      feeWalletAmount: useCustomState(),
      fWCreatedAtLt: useCustomState(),
      fWCreatedAtGt: useCustomState(),
    },
  };

  const handleHideToast = e => {
    e.preventDefault();

    liquidityManagementInput.showToast.setValue(false);
  };

  const { data, loading, error, refetch } = useQuery(
    INTEREST_TRANSACTIONS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        first: connection_limit.value,
        feeWalletTypeNot: Constants.SERVICE_CHARGE_TRANSACTION_TYPE,
      },
    },
  );

  useEffect(() => {
    if (error) {
      toast.error(
        trimGraphQLError(
          error?.message || Constants.errorMessages.NETWORK_ERROR,
        ),
      );
    }
    return () => toast.dismiss();
  }, [error]);

  const handleRefetch = async (e, refetch) => {
    e.preventDefault();

    reFetch(refetch, liquidityManagementInput.searchParams);
  };

  const handleFeeWalletReset = async (e, reFetch) => {
    e.preventDefault();

    const { searchParams } = liquidityManagementInput;

    searchParams.feeWalletId.setValue('');
    searchParams.fWCreatedAtLt.setValue('');
    searchParams.fWCreatedAtGt.setValue('');

    reFetch({
      feeWalletId: undefined,
      fWCreatedAtLt: undefined,
      fWCreatedAtGt: undefined,
      first: connection_limit.value,
    });
  };

  const setSearchParams = searchParams => {
    const { feeWalletId, fWCreatedAtLt, fWCreatedAtGt } = searchParams;

    return {
      feeWalletId: feeWalletId.value ? feeWalletId.value : undefined,
      fWCreatedAtLt: fWCreatedAtLt.value || undefined,
      fWCreatedAtGt: fWCreatedAtGt.value || undefined,
      first: connection_limit.value || undefined,
    };
  };

  const reFetch = async (refetch, searchParams) => {
    refetch(setSearchParams(searchParams));
  };

  const handleExportToCsv = (e, data) => {
    if (data == null) {
      liquidityManagementInput.showToast.setValue(true);
      liquidityManagementInput.status.setValue('Failed');
      liquidityManagementInput.content.setValue(
        Constants.errorMessages.NO_EXPORT_DATA,
      );

      setTimeout(() => {
        liquidityManagementInput.showToast.setValue(false);
      }, 2000);
    }

    let csvData = null;
    let csvName = null;

    const {
      getInterestWallets: {
        account: {
          wallets: {
            feeWallet: {
              transactions: { nodes },
            },
          },
        },
      },
    } = data;

    csvData = deepCopy(nodes);
    csvName = Constants.csvNames.INTEREST_WALLET;

    const filterCsvData = () => {
      const newCsvData = [];
      for (let node of csvData) {
        const items = {};

        items.ID = node.id;
        items.amount = node.amount;
        items.type = node.type.name;
        items.createdAt = `"${moment(node.createdAt).format('llll')}"`;
        items.status = node.status;
        items.portfolio_ID = node.portfolio.id;
        items.portfolioNumber = node.portfolio.portfolioNumber;
        items.user = node.portfolio.user.email;
        items.interest = node.portfolio.chargesAmount;

        newCsvData.push(items);
      }
      csvData = newCsvData;
    };
    filterCsvData();

    exportToCsv(csvData, csvName);
  };

  const handleFeeWalletStartDate = e => {
    liquidityManagementInput.searchParams.fWCreatedAtLt.setValue(e);
  };

  const handleFeeWalletEndDate = e => {
    liquidityManagementInput.searchParams.fWCreatedAtGt.setValue(e);
  };

  return {
    data,
    loading,
    input: liquidityManagementInput,
    error,
    handleHideToast: e => handleHideToast(e),
    searchQuery: e => handleRefetch(e, refetch),
    handleFeeWalletReset: e => handleFeeWalletReset(e, refetch),
    handleFeeWalletStartDate,
    handleFeeWalletEndDate,
    handleExportToCsv: e => handleExportToCsv(e, data),
    setFilterParams: setSearchParams,
  };
};

export default InterestWalletProvider;
