import * as React from 'react';

const FileIcon = props => (
  <svg width={21} height={23} viewBox="0 0 21 23" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.167 0H6.57a1.643 1.643 0 00-1.39.778L.8 5.158c-.497.298-.801.834-.8 1.413v14.786C0 22.265.736 23 1.643 23h17.524c.907 0 1.642-.735 1.642-1.643V1.643C20.81.736 20.075 0 19.168 0zM4.929 3.351V4.93H3.35L4.93 3.35zm14.238 18.006H1.643V6.627h.033l.033-.034H6.57V1.73l.033-.032v-.033h12.54l.023 19.692z"
      fill="#07F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.881 12.595H4.929a.821.821 0 000 1.643H15.88a.821.821 0 000-1.643zM14.238 16.429h-9.31a.821.821 0 100 1.642h9.31a.821.821 0 100-1.642zM12.048 10.405h3.285c.908 0 1.643-.736 1.643-1.643V5.476c0-.907-.735-1.643-1.643-1.643h-3.285c-.908 0-1.643.736-1.643 1.643v3.286c0 .907.735 1.643 1.643 1.643zm0-4.929h3.285v3.286h-3.285V5.476z"
      fill="#07F"
    />
  </svg>
);

export default FileIcon;
