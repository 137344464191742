import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  DataCard,
  EmptyScreen,
  Export,
  Input,
  Loader,
  SideBar,
  Table,
  Toast,
} from '../../components';
import { nairaAmount, reduceStringLength } from '../../lib/Utils';
import { ManualRepaymentsTableProvider } from '../../Providers';
import OfflineRepaymentsProvider from '../../Providers/OfflineRepaymentsProvider';
import './_Styles.scss';
import LiquidityManagementNavs from './LiquidityManagementNavs';

const headers = [
  'Loan ID',
  'Applicant’s Name',
  'Amount Repaid',
  'Outstanding Balance',
  'Status',
];

const ManualRepayments = () => {
  const {
    data,
    loading,
    input,
    handleHideToast,
    handleSearchQuery,
    handleReset,
    handleExportToCsv,
    setFilterParams,
  } = OfflineRepaymentsProvider();
  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="manual-repayments">
        <div className="layout-main">
          <LiquidityManagementNavs />
          <Toast
            title="Offline Repayments"
            content={input.content.value}
            classes={input.status.value}
            active={input.showToast.value}
            clickEvent={handleHideToast}
          />
          <SideBar>
            <section>
              <h4 className={'sidebar-header'}>Search Parameter</h4>
              <form>
                <Input
                  label="Applicant’s Name"
                  placeholder="Enter Applicant’s Name"
                  {...input.searchParams.name.input}
                />
                <Input
                  label={`Loan ID`}
                  placeholder={`Enter Loan ID`}
                  {...input.searchParams.id.input}
                />
                <div>
                  <Button click_event={handleReset}>Reset</Button>
                  <Button
                    classes={'right secondary'}
                    click_event={handleSearchQuery}
                  >
                    Search
                  </Button>
                </div>
              </form>
            </section>
          </SideBar>
          {data?.offlineRepayments?.nodes?.length > 0 ? (
            <div className="loans">
              <Button
                classes="right handle-export"
                click_event={handleExportToCsv}
              >
                <Export />
                <span className="export-csv">Export CSV</span>
              </Button>
              <ManualRepaymentsTableProvider
                setFilterParams={setFilterParams}
                render={({
                  isLoading,
                  handlePagination,
                  manualRepaymentsTableData,
                }) => (
                  <section>
                    <DataCard noPadding={false}>
                      <Table>
                        <div className="table__head">
                          {headers.map((header, key) => (
                            <span
                              key={`${header}_${key}`}
                              className={cx({
                                'checked-width':
                                  header === 'Status' || header === '',
                              })}
                            >
                              {' '}
                              {header}{' '}
                            </span>
                          ))}
                        </div>
                        <div className="table__body">
                          {manualRepaymentsTableData?.offlineRepayments?.nodes?.map(
                            (offlineRepayment, idx) => (
                              <ul key={idx}>
                                <Link
                                  to={`/liquidity-management/manual-repayments/${offlineRepayment?.id}`}
                                >
                                  <li>
                                    <span>
                                      <div className="first-td">
                                        {reduceStringLength(
                                          offlineRepayment?.portfolio
                                            ?.portfolioNumber,
                                          15,
                                        ).toUpperCase()}
                                      </div>
                                    </span>

                                    <span>{`${offlineRepayment?.user?.firstName} ${offlineRepayment?.user?.lastName}`}</span>
                                    <span>
                                      {nairaAmount(
                                        offlineRepayment?.portfolio?.amountPaid,
                                      )}
                                    </span>
                                    <span>
                                      {nairaAmount(
                                        offlineRepayment?.portfolio
                                          ?.fullAmount -
                                          offlineRepayment?.portfolio
                                            ?.amountPaid,
                                      )}
                                    </span>
                                    <span className="checked-width">
                                      <span
                                        className={`status fixed ${
                                          offlineRepayment?.status?.toLowerCase() ||
                                          ''
                                        }`}
                                      >
                                        {offlineRepayment?.status}
                                      </span>
                                    </span>
                                  </li>
                                </Link>
                              </ul>
                            ),
                          )}
                        </div>
                      </Table>
                    </DataCard>

                    {manualRepaymentsTableData?.offlineRepayments?.pageInfo
                      ?.hasNextPage &&
                      (isLoading ? (
                        <Loader />
                      ) : (
                        <Button
                          classes={'secondary center activate-policy'}
                          click_event={handlePagination}
                        >
                          Load more
                        </Button>
                      ))}
                  </section>
                )}
              />
            </div>
          ) : (
            <DataCard noPadding={true}>
              <EmptyScreen message="No Offline Repayments Yet" />
            </DataCard>
          )}
        </div>
      </div>
    </>
  );
};

export default ManualRepayments;
